<script setup>
definePageMeta({
  layout: 'default-clear',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  }
})
useHead({title: 'ログイン'})

const { loading, isLoading } = useLoading()
const { signIn } = useAuth()
loading.stop()

const errorMessage = ref(null)
const btnLogin = ref()
const input = reactive({
  type    : 'member',
  ...(process.env.NODE_ENV == 'production' ? {} : {
    email   : 'k.nishizoe131@gmail.com',
    password: '131kouji',
  })
})

const onLogin = async () => {
  loading.start(false)
  errorMessage.value = null
  try {
    await signIn(input)
  } catch(e) {
    input.password = null
    switch(e.status) {
      case 500: errorMessage.value = 'サーバエラーが発生しました。'; break;
      case 401: errorMessage.value = 'IDまたはパスワードが違います。'; break;
    }
    console.error(e)
  } finally {
    loading.stop()
  }
}
</script>

<template>
  <div class="flex flex-col w-screen h-screen px-3 pt-24">
    <default-form
      class="card max-w-[550px] w-full md:px-16 px-8 py-10 mx-auto"
      :check-reload="false"
      @submit="onLogin"
    >
      <div class="text-xl text-center text-sky-800 font-bold mb-6">
        <NuxtImg
          src="/logo.png"
          class="block mx-auto w-[130px] sm:w-[170px]"
          width="170px"
        />
      </div>

      <h2 class="text-center text-2xl mb-6">ログイン</h2>

      <div class="flex flex-col gap-4 mb-6">
        <default-form-input label="メールアドレス">
          <input
            v-model="input.email"
            required
            class="input"
            type="email"
            autocomplete="email"
            placeholder="user@email.com"
          />
        </default-form-input>

        <default-form-input label="パスワード">
          <input
            v-model="input.password"
            required
            class="input"
            type="password"
            autocomplete="current-password"
          />
        </default-form-input>
      </div>

      <div
        v-if="errorMessage"
        class="text-center text-red-600"
      >
        <span>{{ errorMessage }}</span>
      </div>

      <div class="text-center">
        <button
          type="submit"
          ref="btnLogin"
          class="btn btn-primary mt-8 !px-16"
          :disabled="isLoading"
        >
          <icon v-if="isLoading" icon="mdi:loading" width="24" class="animate-spin" />
          <span v-else class="">ログイン</span>
        </button>
      </div>

      <div class="w-full text-sm text-center mt-8 font-bold">
        <p class="mb-4"><NuxtLink class="underline" to="/account/regist">会員登録はこちら</NuxtLink></p>
        <p class="mb-4"><NuxtLink class="underline" to="/account/recover">パスワードをお忘れですか？</NuxtLink></p>
      </div>
    </default-form>
  </div>
</template>